// @flow
import * as React from 'react';
import { type StyleProps, } from '@haaretz/htz-css-tools';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

import Picture from '../../../Image/Picture';
import getPictureAssets from '../../../../utils/getPictureAssets';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';

type EthanTeaserPropsType = {
  index: number,
  isLazyloadImages: boolean,
  biAction: ?() => void,
  data: TeaserDataType,
  theme: Object,
  miscStyles?: ?StyleProps,
};

const defaultTeaserAreaTemplate = `
"media . . ."
"media . content ."
"media . footer ."
"media . . ."
`;

const colTemplate = [
  { until: 's', value: '23rem 2rem 1fr 2rem', },
  { from: 's', until: 'l', value: '21rem 2rem 1fr 2rem', },
  { from: 'l', until: 'xl', value: '19rem 1rem 1fr 2rem', },
  { from: 'xl', value: '18rem 2rem 1fr 2rem', },
];


const rowTemplate = '1rem auto auto 1rem';

const teaserMiscStyles = (miscStyles, index, theme) => ({
  gridColumnStart: [ { from: 'l', value: index % 2 === 0 ? 2 : 3, }, ],
  ...(miscStyles || {}),
});

EthanTeaser.defaultProps = {
  miscStyles: null,
};

export default function EthanTeaser({
  index,
  data,
  isLazyloadImages,
  biAction,
  theme,
  miscStyles,
}: EthanTeaserPropsType) {
  const image = data?.mobileImage || data?.image;

  return (
    <Section isFragment>
      <Teaser
        areasTemplate={defaultTeaserAreaTemplate}
        colTemplate={colTemplate}
        rowTemplate={rowTemplate}
        key={data.contentId}
        rowGap={null}
        miscStyles={teaserMiscStyles(miscStyles, index, theme)}
      >
        <TeaserMedia data={data}>
          <Picture
            {...getPictureAssets({
              bps: theme.bps,
              imgData: image,
              defaultImgOptions: {
                sizes: [
                  { from: 'xl', size: '126px', },
                  { from: 'l', size: '114px', },
                  { size: '126px', },
                ],
                aspect: 'square',
                widths: [ 126, 250, ],
              },
              sources: [
                {
                  aspect: 'regular',
                  until: 's',
                  sizes: '138px',
                  widths: [ 138, 270, ],
                },
              ],
            })}
          />
        </TeaserMedia>
        <TeaserHeader
          isGridItem
          {...data}
          typeScale={[
            { until: 's', value: -1, },
            { from: 's', until: 'l', value: 1, },
            { from: 'l', value: -1, },
          ]}
          onClick={biAction}
        />
        <TeaserFooter
          noCommentsOnMobile
          showAuthor
          showTime
          showCommentsCount={false}
          data={data}
          typeScale={[
            { until: 's', value: -3, },
            { from: 's', until: 'm', value: -2, },
            { from: 'm', until: 'xl', value: -2, },
            { from: 'xl', value: -3, },
          ]}
          miscStyles={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        />
      </Teaser>
    </Section>
  );
}

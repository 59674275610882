// @flow
import * as React from 'react';
import { type StyleProps, } from '@haaretz/htz-css-tools';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

import getImageAssets from '../../../../utils/getImageAssets';
import Image from '../../../Image/Image';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';

type TateTeaserPropsType = {
  index: number,
  isLazyloadImages: boolean,
  miscStyles?: ?StyleProps,
  biAction: ?() => void,
  data: TeaserDataType,
  theme: Object,
};
TateTeaser.defaultProps = {
  miscStyles: null,
};

const teaserMiscStyles = (isFirst, miscStyles, index, theme) => ({
  gridColumnStart: [ { from: 'l', value: index % 2 === 0 ? 2 : 3, }, ],
  paddingInlineEnd: isFirst ? '1rem' : '4rem',
  paddingTop: theme.tateStyle.teaser.paddingTop(isFirst),
  paddingBottom: theme.tateStyle.teaser.paddingBottom(isFirst),
  alignItems: 'self-end',
  ...(miscStyles || {}),
});

export default function TateTeaser({
  index,
  data,
  isLazyloadImages,
  miscStyles,
  biAction,
  theme,
}: TateTeaserPropsType) {
  const { teaser, } = theme.tateStyle;
  const isFirst = index === 0;
  const defaultTeaserColTemplate = '0 1fr 0';
  const defaultTeaserRowTemplate = 'auto 1fr';
  const defaultTeaserAreaTemplate = `
    ". content ."
    ". footer ."
  `;

  const image = data?.mobileImage || data?.image;

  return (
    <Section isFragment>
      <Teaser
        areasTemplate={
          isFirst
            ? [
              {
                until: 's',
                value: `
              "media . ."
              "media content ."
              "media footer ."
              "media . ."
              `,
              },
              { from: 's', value: defaultTeaserAreaTemplate, },
            ]
            : defaultTeaserAreaTemplate
        }
        colTemplate={
          isFirst
            ? [ { until: 's', value: '3fr 4fr 0', }, { from: 's', value: defaultTeaserColTemplate, }, ]
            : defaultTeaserColTemplate
        }
        rowTemplate={
          isFirst
            ? [
              { until: 's', value: '0 auto 1fr 0', },
              { from: 's', value: defaultTeaserRowTemplate, },
            ]
            : defaultTeaserRowTemplate
        }
        key={data.contentId}
        colGap={teaser.colGap}
        rowGap={teaser.rowGap}
        miscStyles={teaserMiscStyles(isFirst, miscStyles, index, theme)}
      >
        {isFirst ? (
          <TeaserMedia data={data} miscStyles={{ display: [ { from: 's', value: 'none', }, ], }}>
            <Image
              image={image}
              lazyLoad={isLazyloadImages}
              imgOptions={getImageAssets({
                bps: theme.bps,
                aspect: 'regular',
                sizes: '240px',
                widths: [ 240, 480, 720, 1080, ],
              })}
              onClick={biAction}
            />
          </TeaserMedia>
        ) : null}
        <TeaserHeader
          isGridItem
          {...data}
          typeScale={[
            { until: 's', value: -1, },
            { from: 's', until: 'l', value: 1, },
            { from: 'xl', value: -1, },
          ]}
          onClick={biAction}
          miscStyles={{ marginTop: [ { until: 's', value: '4rem', }, ], }}
        />
        <TeaserFooter
          noCommentsOnMobile
          showAuthor
          showTime={teaser.footer.showTime}
          showCommentsCount={teaser.footer.showCommentsCount}
          data={data}
          typeScale={[
            { until: 's', value: -3, },
            { from: 's', until: 'xl', value: -2, },
            { from: 'xl', value: -3, },
          ]}
        />
      </Teaser>
    </Section>
  );
}

// @flow
import * as React from 'react';

import { TATE_LIST_QUERY, } from '@haaretz/graphql';
import TateView from './TateView';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type Props = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
  },
  listData: ListDataType,
};

export default function Tate(props: Props): React.Node {
  return (
    <ListWrapper {...props} query={TATE_LIST_QUERY} view="Tate">
      {dataProps => <TateView {...dataProps} />}
    </ListWrapper>
  );
}

// @flow
import * as React from 'react';
import { ETHAN_LIST_QUERY, } from '@haaretz/graphql';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

import EthanView from './EthanView';
import ListWrapper from '../../ListWrapper';

type Props = {
    updateListDuplication: Function,
    variables: {
      history: ?(string[]),
      getHistory: ?(() => string[]),
      offset: number,
    },
    listData: ListDataType,
  };

export default function Ethan(props: Props): React.Node {
  return (
    <ListWrapper {...props} query={ETHAN_LIST_QUERY} view="Ethan">
      {dataProps => <EthanView {...dataProps} />}
    </ListWrapper>
  );
}

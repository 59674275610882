/* global window OBR document */
import React, { useEffect, } from 'react';
import config from 'config';
import PropTypes from 'prop-types';
import LayoutRow from '../PageLayout/LayoutRow';
import LayoutContainer from '../PageLayout/LayoutContainer';
import useSeoData from '../../hooks/Page/useSeoData';
import { useUser, } from '../User/UserDispenser';

import { appendScript, } from '../../utils/scriptTools';


OutbrainSectionPage.propTypes = {
  properties: PropTypes.shape({
    dataWidgetId: PropTypes.string,
  }),
  widgetId: PropTypes.string.isRequired,
};

OutbrainSectionPage.defaultProps = {
  properties: null,
  widgetId: null,
};

function OutbrainSectionPage(props) {
  const { canonicalUrl, } = useSeoData();
  const { user, } = useUser();

  const isPayingUser = user?.type === 'paying';


  useEffect(() => {
    if (isPayingUser && window.OB_ContextKeyValue !== 'subscribed') window.OB_ContextKeyValue = 'subscribed';
    else if (window.OB_ContextKeyValue) window.OB_ContextKeyValue = undefined;

    return () => {
      window.OB_ContextKeyValue = undefined;
    };
  }, [ isPayingUser, ]);

  useEffect(() => {
    const id = 'sectionPageOutbrain';
    appendScript({
      src: '//widgets.outbrain.com/outbrain.js',
      id,
      isAsync: true,
      updateFunction: () => {
        if (typeof OBR !== 'undefined') {
          OBR.extern.reloadWidget();
        }
      },
    });
    return () => {
      const scriptEl = document.querySelector(`script#${id}`);
      if (scriptEl && typeof scriptEl.remove === 'function') scriptEl.remove();
    };
  }, []);

  const dataWidgetId = props.widgetId || (props.properties && props.properties.dataWidgetId);
  const pathIndex = (canonicalUrl || '').indexOf('/', 8);
  const path = pathIndex === -1 ? '' : canonicalUrl && canonicalUrl.slice(pathIndex + 1);
  const dataSrc = `https://www.${config.get('domain')}/${path}`;
  const isKinneretSection = path.includes('kinneret-level');

  return (
    <LayoutRow tagName="section">
      <LayoutContainer
        namedBgc="transparent"
        miscStyles={{
          ...(isKinneretSection
            ? {
              paddingTop: [ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ],
              paddingBottom: [ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ],
            }
            : { marginTop: [ { until: 's', value: '6rem', }, { from: 's', value: '8rem', }, ], }),
          paddingStart: [ { until: 's', value: '2rem', }, ],
          paddingEnd: [ { until: 's', value: '2rem', }, ],
        }}
      >
        <div className="OUTBRAIN" data-src={dataSrc} data-widget-id={dataWidgetId} />
      </LayoutContainer>
    </LayoutRow>
  );
}

export default OutbrainSectionPage;

// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import setBiAction from '../../../../utils/setBiAction';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';

import type { ContinuableListViewProps, } from '../../../../flowTypes/ListViewProps';
import EthanTeaser from './EthanTeaser';
import EthanPaginator from './EthanPaginator';

const isValidItem = item => item && item.representedContent !== 'UNDEFINED.UNDEFINED';

EthanView.defaultProps = {
  biAction: null,
  sectionId: null,
  isLazyloadImages: true,
};
export default function EthanView({
  list,
  listId,
  isLazyloadImages,
  biAction,
  history,
  sectionId,
}: ContinuableListViewProps): React.Node {
  const { dfp, items, hasPagination, itemsCount, ...restOfList } = list || {};
  const { theme, } = useFela();
  const hasHeader = restOfList.title || restOfList.commercialLinks?.length || restOfList.extraLinks;
  const itemsToDisplay = items.filter(isValidItem);

  return (
    <ListView
      colTemplate={[ { until: 'l', value: '1fr', }, { from: 'l', value: '2fr 5fr 5fr', }, ]}
      rowGap={[ { until: 's', value: '2rem', }, ]}
      Paginator={hasPagination && itemsToDisplay?.length >= itemsCount ? ({ namedBgc, gridGap, colGap, rowGap, padding, }) => (
        <EthanPaginator
          listId={listId}
          sectionId={sectionId}
          history={history}
          biAction={biAction}
          isLazyloadImages={isLazyloadImages}
          gridGap={gridGap}
          colGap={colGap}
          rowGap={rowGap}
          padding={padding}
          namedBgc={namedBgc}
        />
      ) : null}
      attrs={{
        'data-test': 'ethan',
      }}
    >
      {
        hasHeader
          ? (
            <ListViewHeader
              {...restOfList}
              extraLinks={null}
              biAction={biAction}
              disableGridArea
              isSticky
              miscStyles={{
                gridArea: [ { from: 'l', value: `1/1/${Math.ceil(itemsToDisplay.length / 2) + 1}/2`, }, ],
              }}
            />
          ) : null}
      {itemsToDisplay.map((item, index) => (
        <EthanTeaser
          index={index}
          data={item}
          biAction={setBiAction(index, item, biAction)}
          isLazyloadImages={isLazyloadImages}
          key={item.contentId}
          theme={theme}
        />
      ))}
    </ListView>
  );
}

// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { type ComponentPropResponsiveObject, parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import { useLazyQuery, } from 'react-apollo';
import getLayoutContainerMiscStyles from '../ListView/getLayoutContainerMiscStyles';
import Button from '../Button/Button';
import ApolloConsumer from '../ApolloBoundary/ApolloConsumer';
import LayoutContainer from '../PageLayout/LayoutContainer';
import { type ColorPropType, } from '../../flowTypes/ColorPropType';
import useMainContentIdData from '../../hooks/Page/useMainContentIdData';
import { getTeasersInPage, updateTeasersInPage, } from './ListDuplication';

export type PaginatorStylePropsType = {
  /**
   * background color for the LayoutContainer
   */
  namedBgc: ?ColorPropType,
  gridGap: ?string | Array<ComponentPropResponsiveObject<string>>,
  colGap: ?string | Array<ComponentPropResponsiveObject<string>>,
  rowGap: ?string | Array<ComponentPropResponsiveObject<string>>,
  padding: ?string | Array<ComponentPropResponsiveObject<string>>,
};

export type PaginatorPropsType = PaginatorStylePropsType & {
  listId: string,
  history: ?string[],
  getHistory?: ?(() => string[]),
  listQuery: any,
  initialPage: ?number,
  children: ?(Array<Object>) => React.ChildrenArray<React.Node> | React.Node,
  colTemplate: ?string | Array<ComponentPropResponsiveObject<string>>,
  rowTemplate: ?string | Array<ComponentPropResponsiveObject<string>>,
  loadButtonGridColumn?: ?string | Array<ComponentPropResponsiveObject<string>>,
  miscStyles?: ?StyleProps,
  buttonText?: ?string,
  buttonMiscStyles?: ?StyleProps,
  buttonVariant?: string,
};

type PropsWithClientType = PaginatorPropsType;

Paginator.defaultProps = {
  initialPage: 1,
  children: null,
  colTemplate: null,
  rowTemplate: null,
  loadButtonGridColumn: [ { until: 's', value: '1 / -1', }, { from: 's', value: '2 / -1', }, ],
  namedBgc: 'transparent',
  gridGap: [ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ],
  colGap: null,
  rowGap: null,
  padding: [ { until: 's', value: '0 2rem', }, { from: 's', value: '0 4rem', }, ],
  miscStyles: null,
  buttonText: null,
  buttonMiscStyles: null,
  buttonVariant: 'primary',
  getHistory: null,
};

function Paginator({
  listId,
  history,
  getHistory,
  listQuery,
  initialPage,
  children,
  colTemplate,
  rowTemplate,
  loadButtonGridColumn,
  gridGap,
  colGap,
  rowGap,
  padding,
  namedBgc,
  miscStyles,
  buttonText,
  buttonMiscStyles,
  buttonVariant,
}: PropsWithClientType) {
  const { theme, css, } = useFela({ colTemplate, rowTemplate, gridGap, colGap, rowGap, });

  const [ allItems, setAllItems, ] = React.useState([]);
  const [ lastPageIndex, setLastPageIndex, ] = React.useState(null);
  const [ pageIndex, setPageIndex, ] = React.useState(1);
  const [ isBusy, setIsBusy, ] = React.useState(false);
  const mainContentId = useMainContentIdData();


  const [ loadNext, { data, }, ] = useLazyQuery(listQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: result => {
      if (result?.List?.items?.length) {
        // Has teasers
        updateTeasersInPage(result.List.items);
      }
      else {
        // Hasn't teasers
        setLastPageIndex(pageIndex + 1);
      }
      setIsBusy(false);
    },
  });

  React.useEffect(() => {
    const historyTeaserIds = typeof getTeasersInPage === 'function' ? getTeasersInPage() : history;

    loadNext({
      variables: {
        id: listId,
        page: pageIndex,
        exclude: Array.isArray(historyTeaserIds) && historyTeaserIds.length ? historyTeaserIds.join(',') : undefined,
        mainContentId,
      },
    });
  }, [ history, listId, loadNext, mainContentId, pageIndex, ]);

  const nextItems = data?.List?.items || [];


  function loadMore() {
    setIsBusy(true);
    setAllItems(prevAllItems => {
      const newItemList = [ ...prevAllItems, ...nextItems, ];
      setPageIndex(prevPageIndex => prevPageIndex + 1);
      return newItemList;
    });
  }

  const hasMore = pageIndex !== lastPageIndex && nextItems.length > 1;

  return (
    <LayoutContainer
      namedBgc={namedBgc}
      miscStyles={getLayoutContainerMiscStyles({
        colTemplate,
        rowTemplate,
        gridGap,
        colGap,
        rowGap,
        padding,
        miscStyles: { marginTop: gridGap, ...miscStyles, },
      })}
    >
      {allItems && allItems.length > 0 && children && children(allItems)}
      {hasMore ? (
        <div
          className={css({
            textAlign: 'center',
            extend: [ ...parseStyleProps({ gridColumn: loadButtonGridColumn, }, theme.mq, theme.type), ],
          })}
        >
          <Button
            isBusy={isBusy}
            onClick={() => {
              loadMore();
            }}
            miscStyles={buttonMiscStyles}
            variant={buttonVariant}
          >
            {buttonText || theme.tateI18n.loadMoreBtn}
          </Button>
        </div>
      ) : null}
    </LayoutContainer>
  );
}

export default function PaginatorWithClient(props: PaginatorPropsType) {
  return <ApolloConsumer>{client => <Paginator {...props} />}</ApolloConsumer>;
}
